<template>
	<v-dialog :value="dialog" scrollable max-width="550px" persistent>
		<AppDialog
			v-model="loading"
			:heading="{
				label: toEditChapter
					? $t('app.edit_chapter')
					: toAddChapter
					? $t('app.add_chapter')
					: $t('app.add_subchapter'),
			}"
			:action="{
				label: 'app.save',
				event: 'save',
			}"
			@close="$emit('close')"
			@save="submit()"
		>
			<template v-slot:dialog-content>
				<v-form ref="form" class="mt-9">
					<v-text-field
						v-model="form.title"
						:rules="[rules.required]"
						color="greenAccent2"
						background-color="white"
						outlined
						:label="$t('app.title')"
					></v-text-field>

					<v-textarea
						v-if="show_desc"
						v-model="form.description"
						:rules="[rules.required]"
						color="greenAccent2"
						background-color="white"
						outlined
						:label="$t('app.description')"
					></v-textarea>
				</v-form>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import rules from "@/utils/validation";
import AppDialog from "@/components/ui/AppDialog.vue";

export default {
	title: "PopulateChapterQueryDialog",

	props: {
		dialog: {
			required: true,
			type: Boolean,
		},
		show_desc: {
			default: true,
		},
		prop_chapter: {
			required: true,
			type: Object,
		},
	},

	data() {
		return {
			rules,
			loading: false,
			form: {
				title: "",
				description: "",
			},
		};
	},

	components: {
		AppDialog,
	},

	computed: {
		toAddChapter() {
			return !this.prop_chapter.id;
		},

		toAddSubChapter() {
			return this.prop_chapter.id == -1;
		},

		toEditChapter() {
			return this.prop_chapter.id && this.prop_chapter.id != -1;
		},
	},

	mounted() {
		this.init();
	},

	methods: {
		submit() {
			// Validate the form
			if (!this.$refs.form.validate()) return;

			this.$emit("on-submit", this.form);
		},

		init() {
			Object.assign(this.form, this.prop_chapter);

			if (this.toAddSubChapter) {
				this.form.title = null;
				this.form.description = null;
			}
		},
	},
};
</script>
