<template>
	<div>
		<v-treeview v-if="step3_toc" :items="step3_toc" item-text="title">
			<!-- Prepend slot -->
			<template v-slot:prepend="{ item }">
				<v-icon
					v-if="item.id != -1"
					small
					color="wr_blue_1"
					@click.stop="showChapterDialog(item)"
				>
					$vuetify.icons.values.edit
				</v-icon>
			</template>

			<!-- Middle slot -->
			<template v-slot:label="{ item }">
				<div v-if="item.id !== -1">
					<span>{{ item.title }}</span
					><br />
					<span>{{ item.description }}</span>
				</div>
			</template>
		</v-treeview>

		<v-row class="mt-2">
			<v-col>
				<div
					v-if="step3_answer && step3_answer.result"
					:class="answer_styles"
					v-html="step3_answer.result.replace('\n\n', '')"
				></div>
			</v-col>
			<v-col sm="2" align="center">
				<AppButton
					label="app.submit"
					:rounded="false"
					:x_large="true"
					icon="send"
					@click="submit()"
					color="success"
				></AppButton>
			</v-col>
		</v-row>

		<PopulateChapterDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			:prop_chapter="edit_chapter"
			@on-submit="onChapterUpdate"
			@close="dialog = false"
		/>
	</div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { toolsQueryMixin } from "@/mixins/tools/queryMixin.js";
import { queryTOCSteps } from "@/utils/steps";
import PopulateChapterDialog from "@/components/shared/tools/query/toc/PopulaterChapterDialog.vue";

export default {
	name: "ElaborateTOC",

	mixins: [toolsQueryMixin],

	data() {
		return {
			dialog: false,
			edit_chapter: {},
		};
	},

	computed: {
		...mapFields("query", {
			step2_toc: "toc.step2_toc",
			step2_answer: "toc.step2_answer",
			step3_toc: "toc.step3_toc",
			step3_answer: "toc.step3_answer",
			current_step: "toc.current_step",
		}),
	},

	components: {
		PopulateChapterDialog,
	},

	mounted() {
		this.init();
	},

	methods: {
		init() {
			this.step3_toc = structuredClone(this.step2_toc);
		},

		showChapterDialog(edit_chapter = {}) {
			try {
				this.edit_chapter = edit_chapter;
				this.dialog = true;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		onChapterUpdate(payload) {
			payload.step = "step3";
			if (payload.id == -1 || !payload.id) {
				// add chapter/subchapter
				this.$store.commit("query/ADD_TOC", payload);
			} else {
				// update to the state
				this.$store.commit("query/UPDATE_TOC", payload);
			}
			// close the dialog
			this.dialog = false;
		},

		readyToListenEvents() {
			this.$eventBus.$on("qtm-move-to-actions", (payload) => {
				try {
					if (!this.step3_toc) {
						throw new Error("There is no toc.", {
							cause: "werCustom",
						});
					}

					if (!this.step3_answer) {
						throw new Error("Please submit the toc to get a feedback.", {
							cause: "werCustom",
						});
					}

					this.current_step = queryTOCSteps[3];
				} catch (error) {
					this.$announce.error(error);
				}
			});
		},

		async submit() {
			try {
				this.$loader.start();

				// Remove the "add_subchapter" from this array
				let arr = [...this.step3_toc];
				let newArr = arr.map((obj) => {
					return obj.children
						? {
								...obj,
								children: obj.children.filter((child) => child.id !== -1),
						  }
						: obj;
				});

				this.step3_answer = await this.prepareQuery(
					`In the following JSON check which chapter and subchapter's title and description is not relevant. ${JSON.stringify(
						newArr,
					)}`,
				);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},

	beforeDestroy() {
		this.$eventBus.$off("qtm-move-to-modify-toc");
	},
};
</script>

<style></style>
