<template>
	<TOCQuery></TOCQuery>
</template>

<script>
import TOCQuery from "@/components/shared/tools/query/toc/Index.vue";

export default {
	name: "TOCQueryPage",

	components: {
		TOCQuery,
	},
};
</script>

<style></style>
