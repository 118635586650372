<template>
	<div>
		<v-form ref="form">
			<v-row>
				<v-col>
					<v-text-field
						v-model="step1_question"
						:label="$t('app.topic_curious_about')"
						:rules="[rules.required, rules.maxWords(5)]"
						outlined
					>
						<span slot="append">
							<SpeechToText
								:text="step1_question"
								@speechend="onSpeechEnd($event.text)"
							></SpeechToText>
						</span>
						<span slot="append" class="ms-3">
							<TextToSpeech :text="step1_question"></TextToSpeech>
						</span>
						<span slot="append" class="ms-3">
							<Translator :text="step1_question"></Translator>
						</span>
					</v-text-field>
				</v-col>
				<!-- <v-col sm="4">
					<BackgroundInformation></BackgroundInformation>
				</v-col> -->
				<v-col sm="2">
					<AppButton
						label="app.submit"
						:rounded="false"
						:block="true"
						:x_large="true"
						icon="send"
						@click="submit()"
						color="success"
					></AppButton>
				</v-col>
			</v-row>
		</v-form>

		<v-treeview
			v-if="branches"
			:items="branches"
			item-text="title"
		></v-treeview>
	</div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { queryTOCSteps } from "@/utils/steps";
import BackgroundInformation from "@/components/shared/tools/query/_common/BackgroundInformation.vue";
import {
	askQuestionMixin,
	toolsQueryMixin,
} from "@/mixins/tools/queryMixin.js";

export default {
	name: "TOCAskQuestion",

	mixins: [askQuestionMixin, toolsQueryMixin],

	components: {
		BackgroundInformation,
	},

	computed: {
		...mapFields("query", {
			step1_question: "toc.step1_question",
			step1_answer: "toc.step1_answer",
			step2_toc: "toc.step2_toc",
			current_step: "toc.current_step",
			grade: "toc.grade",
		}),

		branches() {
			if (!this.step1_answer || !this.step1_answer.result) return null;

			return JSON.parse(this.step1_answer.result).children;
		},
	},

	watch: {
		step1_question(newVal, oldVal) {
			this.step1_answer = this.step2_toc = null;
			this.attempts = [];
		},
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("qttoc-move-to-toc-preview", (payload) => {
				try {
					if (!this.step1_question || !this.step1_answer) {
						throw new Error("Please ask a query at this step.", {
							cause: "werCustom",
						});
					}
					this.current_step = queryTOCSteps[1];
				} catch (error) {
					this.$announce.error(error);
				}
			});
		},

		async submit() {
			try {
				this.$loader.start();

				if (!this.$refs.form.validate()) return;

				this.step1_answer = await this.prepareQuery(
					`generate a JSON of table of content for a research project about "${this.step1_question}". In JSON, give each item an id, title and description. Each children item should includes its parents' id. The table of content will include 5 chapters. In chapters 1-2 add 3 sub-chapters. In chapter 3 add 2 sub-chapters. In chapter 4 add 1 sub-chapter. In chapter 5 don't add sub-chapter. At the end add bibliography chapter.`,
				);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		onSpeechEnd(text) {
			try {
				// Save emitted value by microphone to answer
				this.step1_question = text;

				/**
				 * When click on mic icon, the focus will be detached from the input, so,
				 * after off the mic, enable the focus to input element again because
				 * answer will be saved only on blur event and to trigger blur event
				 * we need to focus first.
				 */
				this.$nextTick(() => {
					// There are two index in $el array, 0th is label and 1st is input.
					this.$refs.form.$el[1].focus();
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},

	beforeDestroy() {
		this.$eventBus.$off("qttoc-move-to-toc-preview");
	},
};
</script>

<style></style>
