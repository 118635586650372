var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.dialog,"scrollable":"","max-width":"550px","persistent":""}},[_c('AppDialog',{attrs:{"heading":{
			label: _vm.toEditChapter
				? _vm.$t('app.edit_chapter')
				: _vm.toAddChapter
				? _vm.$t('app.add_chapter')
				: _vm.$t('app.add_subchapter'),
		},"action":{
			label: 'app.save',
			event: 'save',
		}},on:{"close":function($event){return _vm.$emit('close')},"save":function($event){return _vm.submit()}},scopedSlots:_vm._u([{key:"dialog-content",fn:function(){return [_c('v-form',{ref:"form",staticClass:"mt-9"},[_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"color":"greenAccent2","background-color":"white","outlined":"","label":_vm.$t('app.title')},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),(_vm.show_desc)?_c('v-textarea',{attrs:{"rules":[_vm.rules.required],"color":"greenAccent2","background-color":"white","outlined":"","label":_vm.$t('app.description')},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }