<template>
	<div>
		<v-row no-gutters>
			<v-col>
				<v-card v-if="step2_answer" flat class="wr_grey_1">
					<v-card-text
						class="pt-0 black--text"
						v-if="step2_answer.result"
						:class="answer_styles"
						:key="'r'"
						v-html="step2_answer.result.replace('\n\n', '')"
					></v-card-text>
				</v-card>
			</v-col>
			<v-col sm="2" align="center">
				<AppButton
					label="app.submit"
					:rounded="false"
					:x_large="true"
					icon="send"
					@click="submit()"
					color="success"
				></AppButton>
			</v-col>
		</v-row>

		<v-treeview v-if="step2_toc" :items="step2_toc" item-text="title">
			<!-- Prepend slot -->
			<template v-slot:prepend="{ item }">
				<v-icon
					v-if="item.id != -1"
					small
					color="wr_blue_1"
					@click.stop="showChapterDialog(item)"
				>
					$vuetify.icons.values.edit
				</v-icon>

				<v-chip
					v-else
					small
					color="success"
					dark
					outlined
					depressed
					@click="showChapterDialog(item)"
				>
					<v-icon left small>$vuetify.icons.values.plus</v-icon>
					{{ $t("app.add_subchapters") }}
				</v-chip>
			</template>

			<!-- Middle slot -->
			<template v-slot:label="{ item }">
				<div v-if="item.id !== -1">
					<span>{{ item.title }}</span>
				</div>
			</template>

			<!-- Append slot -->
			<template v-slot:append="{ item }">
				<v-icon
					v-if="item.id != -1"
					small
					color="pink darken-1"
					class="cursor-wr"
					@click.native="prepareDelete(item)"
					>$vuetify.icons.values.delete</v-icon
				>
			</template>
		</v-treeview>

		<v-chip
			color="success"
			dark
			depressed
			class="mt-3 ms-10"
			@click="showChapterDialog()"
		>
			<v-icon left small>
				$vuetify.icons.values.plus
			</v-icon>
			Add Chapter
		</v-chip>

		<PopulateChapterDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			:prop_chapter="edit_chapter"
			:show_desc="false"
			@on-submit="onChapterUpdate"
			@close="dialog = false"
		/>

		<TheConfirmation
			v-if="confirm_dialog"
			v-model="confirm_dialog"
			:dialog="confirm_dialog"
			warning_desc="app.sure_logout_warning"
			yes_btn_label="app.yes"
			no_btn_label="app.no"
			@cancel="confirm_dialog = false"
			@confirm="onConfirmDelete()"
		></TheConfirmation>
	</div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { queryTOCSteps } from "@/utils/steps";
import { toolsQueryMixin } from "@/mixins/tools/queryMixin.js";
import PopulateChapterDialog from "@/components/shared/tools/query/toc/PopulaterChapterDialog.vue";
import TheConfirmation from "@/components/layout/TheConfirmation";

export default {
	name: "PreviewMap",

	mixins: [toolsQueryMixin],

	data() {
		return {
			dialog: false,
			edit_chapter: {},
			confirm_dialog: false,
			to_delete: null,
		};
	},

	components: {
		PopulateChapterDialog,
		TheConfirmation,
	},

	computed: {
		...mapFields("query", {
			step1_question: "toc.step1_question",
			step1_answer: "toc.step1_answer",
			step2_toc: "toc.step2_toc",
			step2_answer: "toc.step2_answer",
			current_step: "toc.current_step",
			attempts: "toc.attempts",
		}),
	},

	mounted() {
		this.init();
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("qtm-move-to-elaborate-toc", (payload) => {
				try {
					if (!this.step2_toc) {
						throw new Error("There is no toc.", {
							cause: "werCustom",
						});
					}

					if (!this.step2_answer) {
						throw new Error("Please submit the toc to get a feedback..", {
							cause: "werCustom",
						});
					}

					this.current_step = queryTOCSteps[2];
				} catch (error) {
					this.$announce.error(error);
				}
			});
		},

		init() {
			if (!this.step2_toc || !this.step2_toc.length) {
				let data = JSON.parse(this.step1_answer.result).children;
				data.map((item) => {
					if (item.title != "Bibliography") {
						if (!item.children) {
							item.children = [];
						}
						item.children.push({
							id: -1,
							title: "add_subchapter",
							parentId: item.id,
						});
					}
				});
				this.step2_toc = structuredClone(data);
			}
		},

		showChapterDialog(edit_chapter = {}) {
			try {
				this.edit_chapter = edit_chapter;
				this.dialog = true;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async submit() {
			try {
				this.$loader.start();
				// Remove the "add_subchapter" from this array
				let arr = [...this.step2_toc];
				let newArr = arr.map((obj) => {
					return obj.children
						? {
								...obj,
								children: obj.children.filter((child) => child.id !== -1),
						  }
						: obj;
				});
				// Send query
				this.step2_answer = await this.prepareQuery(
					`In the following JSON check which chapter and subchapter's title is not relevant. "${
						this.step1_question
					}". Do not write a program.- ${JSON.stringify(newArr)}`,
				);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		onChapterUpdate(payload) {
			payload.step = "step2";
			if (payload.id == -1 || !payload.id) {
				// add chapter/subchapter
				this.$store.commit("query/ADD_TOC", payload);
			} else {
				// update to the state
				this.$store.commit("query/UPDATE_TOC", payload);
			}
			// close the dialog
			this.dialog = false;
		},

		prepareDelete(payload) {
			this.to_delete = payload;

			this.confirm_dialog = true;
		},

		onConfirmDelete() {
			// update to the state
			this.$store.commit("query/DELETE_TOC", this.to_delete);
			// Reset to delete item
			this.to_delete = null;
			// close the dialog
			this.confirm_dialog = false;
		},
	},

	beforeDestroy() {
		this.$eventBus.$off("qtm-move-to-elaborate-toc");
	},
};
</script>

<style></style>
